@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Encode Sans Bold";
  src: url("../fonts/EncodeSans_Expanded-Bold.ttf") format("truetype");
}

.blogArea {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  justify-content: space-between;
  padding: 0 40px;
}
.blogContainer {
  width: 30%;
  margin: 20px 0;
  cursor: pointer;
}
.featureBlogContainer .blogThumbnail {
  height: calc(50vw / 16 * 9);
  max-height: calc((1024px * 0.5) / 16 * 9);
}
.blogThumbnail {
  border-radius: 15px;
  height: calc(((90vw - 80px) / 3) / 16 * 9);
  max-height: calc((1024px * 0.9 / 3) / 16 * 9);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.blogContainer:hover .blogThumbnail {
  border-bottom: 2px;
}

.blogContainer:hover .blogThumbnail:after {
  width: calc(100% + 2px);
}
.blogPubDate {
  display: inline-flex;
}
.blogByAuthor {
  color: #9b9ea1;
  display: inline;
  margin-right: 5px;
}
.blogPubDate > span {
  position: relative;
  content: "";
  height: 4px;
  width: 4px;
  background: #091d40;
  border-radius: 50000px;
  display: block;
  line-height: 40px;
  margin: 5px;
}
.blogTitle {
  color: #0f034a;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Encode Sans Bold";
}
.featureBlogContainer {
  display: flex;
  padding: 60px 0;
  cursor: pointer;
}
.featureBlogContainer > div {
  width: 50%;
}
.featureBlogContainer > div:nth-of-type(2) {
  padding-left: 40px;
}
.featureBlogContainer .blogTitle {
  font-size: 30px;
}
.featureBlogContainer .blogDesc {
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  height: 127px;
  max-height: 127px;
}
.featureBlogContainer:hover .blogTitle {
  text-decoration: underline;
}
.blogContainer:hover .blogTitle {
  text-decoration: underline;
}

.blogDesc {
  line-height: 1.74;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  color: #333;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 100px;
  height: 100px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-family: "Poppins";
}
.blogDesc img {
  display: none;
}
