.footer-wrapper {
  background-color: #0d3c51;
  display: flex;
  height: 500px;
  width: 100%;
  justify-content: space-around;
  padding-top: 69px;

  .left-wrapper {
    display: flex;
    flex-direction: column;
    height: 66%;
    position: static;

    .logo-footer {
      background: url("../header/img/orichal.jpg");
      background-size: contain;
      width: 420px;
      @media screen and (max-width: 1024px) {
        width: auto;
      }

      height: 88px;
      margin-bottom: 78px;
      background-repeat: no-repeat;
    }

    .connect {
      font-size: 16px;
      line-height: 19px;
      color: white;
      display: flex;
      opacity: 0.8;
      margin-bottom: 15px;
      div {
        display: flex;
        gap: 15px;

        a {
          .icon {
            position: static;
            height: 35px;
            width: 35px;
            margin-right: 20px;
            &.mail {
              background: url("./img/mail.png");
              background-size: contain;
              background-repeat: no-repeat;
            }
            &.medium {
              background: url("./img/medium.png");
              background-size: contain;
              background-repeat: no-repeat;
            }
            &.twitter {
              background: url("./img/twitter.png");
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
    .copyright {
      font-size: 12px;
      line-height: 25px;
      color: #ffffff;
      display: flex;
    }
    .footer-line {
      position: absolute;
      right: 24px;
      bottom: 100px;
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
  .right-wrapper {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    gap: 69px;
    .footer-menu-col {
      display: flex;
      flex-direction: column;
      line-height: 200%;
      h1 {
        font-size: 20px;
        text-decoration: underline;
        font-family: "Encode Sans Semi Bold";
        color: #2da1bc;
        margin-bottom: 16px;
      }
      a {
        font-size: 14px;
        color: #ffffff;
        text-decoration: none;
        font-family: "Poppins";
      }
    }
  }
}
