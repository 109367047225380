@import "../global.scss";

@keyframes clickFlash {
  0% {
    color: #fff;
  }
  50% {
    color: #0d3c51;
  }
  100% {
    color: #fff;
  }
}

.advisory-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #707070;
  text-align: center;
  background: #f5f5f5;

  .advisory-divider {
    width: 80%;
    border-top: 2px solid #e5e5e5;
    margin-bottom: 69px;
  }

  h3 {
    &.translucent {
      font-family: "Encode Sans Semi Bold";
      color: #fff;
      opacity: 0.5;
    }
    &.blue {
      color: #0d3c51;
    }
    &.white {
      color: #fff;
    }
    &.lg {
      @include lg-breakpoints;
    }
    &.mb16 {
      margin-bottom: 16px;
    }
  }

  h2 {
    &.blue {
      color: #0d3c51;
      &.mb-more {
        margin-bottom: 42px;
      }
    }
  }

  .mobileOnly {
    display: none;
    @media screen and (max-width: 768px) {
      display: inline;
    }
  }
  .desktopOnly {
    display: inline;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .whiteback {
    padding-bottom: 140px;
    @media screen and (max-width: 768px) {
      padding-bottom: 69px;
    }
    &#selfserve {
      padding-bottom: 0;
    }

    .tag-wrapper {
      width: calc(100% - 532px);
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      h3 {
        @include lg-breakpoints;
        margin-bottom: 16px;
        margin-top: 0;
      }
    }
    .reasons {
      margin-top: 120px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 69px;
      @media screen and (max-width: 768px) {
        margin-top: 0;
        gap: 42px;
      }
      .reason {
        display: flex;
        align-items: center;
        gap: 130px;
        padding: 0 0 0 69px;
        @media screen and (max-width: 1366px) {
          padding: 0 0 0 42px;
        }
        @media screen and (max-width: 1024px) {
          gap: 42px;
        }
        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 4.2px;
        }
        &.rev {
          padding: 0 69px 0 0;
          flex-direction: row-reverse;
          @media screen and (max-width: 1366px) {
            padding: 0 42px 0 0;
          }
          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }

        .text {
          width: 530px;
          @media screen and (max-width: 768px) {
            width: auto;
          }
          text-align: left;
          color: #0d3c51;
          h1 {
            width: 600px;
            font-family: "Encode Sans Semi Bold";
            @media screen and (max-width: 768px) {
              width: calc(100vw - 21px);
              @include lg-breakpoints;
              padding-left: 21px;
            }
            font-size: 42px;
            font-weight: bold;
          }
          p {
            font-size: 18px;
            padding-left: 47.5px;
            font-family: "Poppins";
            @media screen and (max-width: 768px) {
              padding: 0 21px;
            }
          }
          &.outstanding {
            .experience {
              padding-left: 47.5px;
            }
          }
        }
        .graphic {
          &.expertise {
            background: url("./img/expertise-advisory.png");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 400px;
            height: 300px;
            @media screen and (max-width: 768px) {
              width: calc(80vw - 69px);
            }
          }
          &.transparency {
            background: url("./img/transparency.png");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 342px;
            height: 368px;
            @media screen and (max-width: 768px) {
              width: calc(80vw - 69px);
            }
          }
          &.bespoke {
            background: url("./img/bespoke.png");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 315px;
            height: 380px;
            @media screen and (max-width: 768px) {
              width: calc(80vw - 69px);
            }
          }
          &.outstanding {
            background: url("./img/outstanding.png");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 370px;
            height: 360px;
            @media screen and (max-width: 768px) {
              width: calc(80vw - 69px);
            }
          }
          &.ops {
            background: url("./img/ops.png");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 415px;
            height: 300px;
            @media screen and (max-width: 768px) {
              width: calc(80vw - 69px);
            }
          }
          &.risk {
            background: url("./img/risk.png");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 400px;
            height: 387px;
            @media screen and (max-width: 768px) {
              width: calc(80vw - 69px);
            }
          }
        }
      }
    }
    //still whiteback
    .carousel-control {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 768px) {
        height: 666px;
      }
      .arrowLeft,
      .arrowRight {
        margin-top: 30px;
        cursor: pointer;
        color: #c1c1c1;
        &.hidden {
          color: #fff;
          cursor: default;
        }
      }
      .carousel {
        width: 700px;
        overflow: hidden;

        @media screen and (max-width: 768px) {
          width: calc(100vw - 69px);
        }

        .testimonials {
          position: relative;
          display: flex;
          width: 3500px;
          @media screen and (max-width: 768px) {
            width: 500vw;
          }
          transition: right 0.5s;

          &.pos0 {
            right: 0;
          }
          &.pos1 {
            right: 700px;
            @media screen and (max-width: 768px) {
              right: calc(100vw - 70px);
            }
          }
          &.pos2 {
            right: 1400px;
            @media screen and (max-width: 768px) {
              right: calc(200vw - 140px);
            }
          }
          &.pos3 {
            right: 2100px;
            @media screen and (max-width: 768px) {
              right: calc(300vw - 210px);
            }
          }
          &.pos4 {
            right: 2800px;
            @media screen and (max-width: 768px) {
              right: calc(400vw - 280px);
            }
          }

          .testimonial {
            width: 700px;
            @media screen and (max-width: 768px) {
              width: calc(100vw - 70px);
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;
            .logo {
              &.allbit {
                background: url("../team/img/allbit.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                height: 80px;
                width: 200px;
              }
              &.dna {
                background: url("../team/img/dna.png");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                height: 80px;
                width: 420px;
                @media screen and (max-width: 768px) {
                  width: 300px;
                }
              }
              &.allme {
                background: url("../home/img/allme.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                height: 80px;
                width: 200px;
              }
              &.okx {
                background: url("./img/okx.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                height: 80px;
                width: 200px;
              }
            }
            .quote {
              display: flex;
              .leftquote {
                width: 35px;
                height: 35px;
                background: url("./img/leftquote.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin-bottom: 35px;
              }
              .quotetext {
                width: 537px;
                font-family: "Poppins";
                @media screen and (max-width: 768px) {
                  width: 80%;
                }
                padding: 0 32px;
              }
              .rightquote {
                width: 35px;
                height: 35px;
                background: url("./img/rightquote.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin-top: 35px;
              }
            }
          }
        }
      }
    }
    .dots {
      margin-top: 80px;
      @media screen and (max-width: 768px) {
        margin-top: -69px; //i know negative margin is a no-no, but fuck it.
      }
      display: flex;
      gap: 10px;
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin-top: 1.5px;
        border: 1px solid #0d3c51;
        cursor: pointer;

        .inner-dot {
          width: 6px;
          height: 6px;
          background: #ffffff;
          margin-top: 2px;
          margin-left: 2px;
          border-radius: 6px;
        }

        &.selected {
          margin-top: 0;
          border: 3px solid #0d3c51;

          .inner-dot {
            background: #0d3c51;
          }
        }
      }
    }
    //still whiteback
    &.dark {
      .confidence {
        width: calc(100% - 280px);
        margin-top: 64px;
        padding: 0 140px;
        display: flex;
        @media screen and (max-width: 768px) {
          width: 100%;
          flex-direction: column-reverse;
          padding: 0;
          margin: 0;
        }
        gap: 32px;
        .con {
          display: flex;
          .lines {
            display: flex;
            flex-direction: column;
            padding-top: 8px;
            .bump {
              height: 32px;
              width: 32px;
              border-radius: 16px;
              background: linear-gradient(to right, #2da1bc, #44da9d);
              display: flex;
              align-items: center;
              justify-content: center;
              .dot {
                height: 16px;
                width: 16px;
                border-radius: 16px;
                border: 4px solid #f5f5f5;
                background: linear-gradient(to left, #2da1bc, #44da9d);
              }
            }
            .line {
              margin-left: 14px;
              width: 4px;
              &.big {
                height: 338px;
                @media screen and (max-width: 768px) {
                  height: 598px;
                }
              }
              &.baby {
                height: 142px;
                @media screen and (max-width: 768px) {
                  height: 200px;
                }
              }
              background: linear-gradient(to bottom, #2da1bc, #44da9d);
            }
          }
        }
        .text {
          width: calc(100% - 42px);
          gap: 4.2px;
          @media screen and (max-width: 768px) {
            width: 100%;
            padding: 5px 15px;
            gap: 0;
          }
          display: flex;
          flex-direction: column;
          text-align: left;
          margin-top: 10px;

          h3 {
            color: #0d3c51;
            margin-bottom: 12px;
            padding-left: 12px;
            @media screen and (max-width: 768px) {
              margin-bottom: 0;
              padding: 0;
            }
          }
          p {
            font-family: "Poppins";
            color: #0d3c51;
            padding-left: 12px;
            line-height: 1.69;
            @media screen and (max-width: 768px) {
              padding-left: 0;
            }
            &.fixedheight {
              margin-top: 0;
              height: 300px;
              @media screen and (max-width: 768px) {
                margin-top: 13px;
                height: 570px;
              }
            }
          }
          .mtop {
            margin: 12.5px 0 0 0;
          }
        }
        .illustration {
          height: 100%;
          display: flex;
          flex-direction: column;
          width: 50%;
          gap: 48px;
          @media screen and (max-width: 768px) {
            width: 100%;
            align-items: center;
            gap: 16px;
          }
          .illustration-inner {
            background: url("./img/services.png");
            width: calc(50vw - 69px);
            height: 580px;
            @media screen and (max-width: 768px) {
              width: 100%;
              height: 369px;
            }
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
          }
          .decision {
            @include m-breakpoints;
            border-radius: 8px;
            width: 420px;
            height: 138px;
            background: #0d3c51;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            &.launch-package {
              width: 100%;
              @media screen and (max-width: 768px) {
                width: calc(100% - 42px);
              }
            }
          }
          .then-decide {
            display: flex;
            gap: 32px;
            @media screen and (max-width: 768px) {
              width: calc(100% - 42px);
            }
            &.arrows {
              @include x-lg-breakpoints;
              justify-content: space-around;
            }
            .mm.fs {
              background: linear-gradient(to right, #2da1bc, #44da9d);
            }
          }
          .faq {
            text-align: right;
          }
        }
      }
    }
    &.prune {
      padding-top: 69px;
      @media screen and (max-width: 768px) {
        padding-bottom: 0;
      }
      h2 {
        padding-left: 140px;
        @media screen and (max-width: 768px) {
          padding-left: 0;
          width: 100%;
        }
        color: #0d3c51;
      }

      .options {
        display: flex;
        width: calc(100% - 242px);
        margin-top: 64px;
        @media screen and (max-width: 768px) {
          width: 100%;
          flex-direction: column;
          margin-top: 42px;
        }
        gap: 16px;

        .option {
          width: 50%;
          padding: 80px;
          @media only screen and (max-width: 1920px) {
            padding: 80px;
          }
          @media only screen and (max-width: 1600px) {
            padding: 69px;
          }
          @media only screen and (max-width: 768px) {
            width: calc(100% - 150px);
            padding: 69px 69px 69px 42px;
            margin-left: 20px;
          }
          background: #0d2837;
          color: #fff;
          display: flex;
          flex-direction: column;
          text-align: left;
          align-items: baseline;
          .optionlink {
            border: 1px solid #48f0e1;
            border-radius: 8px;
            width: 142px;
            padding: 8px 16px;
            h2 {
              color: #48f0e1;
              padding-left: 0;
              width: 100%;
            }
          }

          h3 {
            @include m-breakpoints;
            color: #fff;
            margin: 24px 0 16px 0;
          }
          p {
            @include s-breakpoints;
            margin: 0;
            font-family: "Poppins";
          }
          ul {
            @include s-breakpoints;
            font-weight: 500;
            @media only screen and (max-width: 768px) {
              width: 100%;
              font-family: "Poppins";
            }
          }
        }
      }
      .toggles {
        @media screen and (min-width: 768px) {
          display: none;
        }
        width: calc(100% - 42px);
        margin-top: 42px;
        background: #0d3c51;
        border-radius: 12px;
        padding: 5px;
        display: flex;
        gap: 4.2px;
        height: 69px;
        .toggle {
          flex-grow: 1;
          border-radius: 12px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Encode Sans Bold";
          &.selected {
            background: #078077;
          }
        }
      }
      .comparison {
        width: calc(100% - 242px);
        margin-top: 80px;
        border: 2px solid #078077;
        display: flex;
        flex-direction: column;
        &.desktop {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        &.mobile {
          width: calc(100% - 42px);
          @media screen and (min-width: 768px) {
            display: none;
          }
        }

        .row {
          display: flex;
          height: 120px;
          color: #0d3c51;
          .cell {
            border: 1px solid #078077;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Encode Sans Semi Bold";
            span {
              @include s-breakpoints;
              font-weight: 500;
            }
          }
          .category {
            background: #0d3c51;
            color: #fff;
            border-bottom: 2px solid #078077;
            width: calc(25% - 76px);
            padding: 0 38px;
            text-align: left;
            font-weight: 500;
            font-family: "Encode Sans Bold";
            @media screen and (max-width: 768px) {
              width: 124px;
              padding: 0 6.9px;
            }
            justify-content: left;

            span {
              @include m-breakpoints;
              width: 200px;
              text-align: left;
            }
          }
          .fullwidth {
            width: 75%;
            @media screen and (max-width: 768px) {
              padding: 0 6.9px;
            }
          }
          .selfserve,
          .fullserve {
            width: calc(37.5% - 115px);
            padding: 0 75px 0 40px;
          }
          &.headers {
            background: #0d3c51;
            color: #fff;
            font-family: "Encode Sans Bold";
            span {
              @include m-breakpoints;
            }
          }
          &.even {
            background: #f5f5f5;
          }
        }

        &.mobile {
          margin: 21px 0;
        }
      }
    }
    //still whiteback
    .stats-wrapper {
      height: 338px;
      @media screen and (max-width: 768px) {
        height: fit-content;
      }
      .stats {
        .stat {
          p {
            @include s-breakpoints;
            @media screen and (max-width: 768px) {
              @include m-breakpoints;
            }
            margin-bottom: 16px;
          }
        }
      }
    }
    .cards {
      display: flex;
      width: calc(100% - 42px);
      margin: 120px 0 142px 0;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        margin: 42px 0 69px 0;
      }
      justify-content: center;
      gap: 16px;
      .card {
        width: calc(25% - 120px);
        @media screen and (max-width: 768px) {
          width: calc(100% - 84px);
          margin-left: 11px;
        }
        border: 1px solid #c1c1c1;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        padding: 30px;
        .img {
          &.flex {
            background: url("./img/flex.png");
            width: 100%;
            height: 200px;
            background-repeat: no-repeat;
            background-size: contain;
          }
          &.algos {
            background: url("./img/algos.png");
            width: 100%;
            height: 200px;
            background-repeat: no-repeat;
            background-size: contain;
          }
          &.custody {
            background: url("./img/custody.png");
            width: 100%;
            height: 200px;
            background-repeat: no-repeat;
            background-size: contain;
          }
          &.reports {
            background: url("./img/reports.png");
            width: 100%;
            height: 200px;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
        h3 {
          margin: 0;
          @media screen and (max-width: 768px) {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .blueback {
    background: #0d2837;
    padding: 140px 0;

    &.hundyvh {
      height: calc(100vh - 280px);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (max-width: 768px) {
      padding: 25vh 0 30vh 0;
      &.end {
        padding: 25vh 0 69px 0;
      }
    }

    h1 {
      margin-bottom: 32px;
    }
    h2 {
      color: #ffffff;
      margin-bottom: 69px;
      @media screen and (max-width: 768px) {
        width: calc(100% - 42px);
        @include m-breakpoints;
      }
    }
    .buttons {
      button {
        background: linear-gradient(to right, lightseagreen, mediumaquamarine);
        color: #ffffff;
        font-family: "Encode Sans Bold";
        height: 69px;
        border: 0;
      }
    }

    &.light {
      background: #0d3c51;
      position: relative;
      height: calc(100vh + 42px);
      overflow: hidden;

      h1 {
        @include x-lg-breakpoints;
        left: 69px;
        @media screen and (max-width: 768px) {
          left: 0;
        }
        position: absolute;
        top: 69px;
      }
      h3 {
        @include s-breakpoints;
        position: absolute;
        left: 69px;
        @media screen and (max-width: 768px) {
          width: 100%;
          left: 0;
          text-align: center;
          margin-top: 21px;
        }
        top: 169px;
        color: #fff;
        font-family: "Poppins";
        font-weight: 100;
      }

      .arc {
        position: absolute;
        top: 420px;
        left: 500px;
        background: linear-gradient(to right, #2da1bc, #44da9d 50%);
        height: 100vw;
        width: 100vw;
        border-radius: 50vw;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 768px) {
          display: none;
        }

        .donuthole {
          height: 99.25vw;
          width: 99.25vw;
          background: #0d3c51;
          border-radius: 50vw;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        .dot {
          position: absolute;
          height: 15px;
          width: 15px;
          border-radius: 7.5px;
          background: #fff;
          &.dot0 {
            top: 28.8%;
            left: 4.2%;
          }
          &.dot1 {
            top: 16%;
            left: 12.5%;
          }
          &.dot2 {
            top: 3.9%;
            left: 29.5%;
          }
          &.dot3 {
            top: -0.1%;
            left: 54.2%;
          }
          &.enddot {
            top: -117.5px;
            left: -533px;
            &.shorter {
              left: -480px;
            }
            &.longer {
              top: -229px;
              left: -650px;
            }
          }

          .line {
            position: absolute;
            top: -135px;
            left: -52px;
            width: 3px;
            height: 169px;
            background: #fff;
            transform: rotate(-45deg);
            &.longer {
              height: 320px;
              top: -269px;
              left: -110px;
            }
          }
          .line2 {
            position: absolute;
            width: 420px;
            height: 3px;
            background: #fff;
            top: -111px;
            left: -530px;
            &.shorter {
              width: 369px;
              left: -479px;
            }
            &.longer {
              top: -223px;
              left: -642px;
            }
          }
          h1 {
            @include lg-breakpoints;
            position: absolute;
            top: -180px;
            left: -500px;
            width: 666px;
            text-align: left;
            color: #48f0e1;
            font-weight: bold;
            &.shorter {
              left: -420px;
            }
            &.longer {
              top: -290px;
              left: -625px;
            }
          }
          p {
            @include s-breakpoints;
            position: absolute;
            top: -100px;
            left: -500px;
            width: 380px;
            text-align: left;
            color: #fff;
            &.shorter {
              left: -450px;
              width: 369px;
            }
            &.longer {
              top: -210px;
              left: -625px;
            }
          }
        }
      }

      .foundations {
        display: none;
        @media screen and (max-width: 768px) {
          display: flex;
          margin-top: 42px;
        }
        flex-direction: column;
        height: calc(100% - 69px);
        justify-content: space-between;
        .foundation {
          display: flex;
          flex-direction: column;
          h1 {
            @include m-breakpoints;
            width: calc(100% - 169px);
            position: relative;
            left: auto;
            margin: 0;
            height: 120px;
            font-family: "Encode Sans Bold";
            color: #48f0e1;
            text-align: left;
            margin-left: 30%;
            &.dblrow {
              transform: translateY(-18px);
            }
          }

          .spine {
            position: relative;
            left: 25%;
            width: calc(66% - 21px);
            height: 2px;
            background: #fff;
            margin: 0 21px 0 0;
            transform: translateY(-12px);

            .line {
              position: absolute;
              height: 138px;
              width: 2px;
              background: #fff;
              top: -7.5px;
              left: -35px;
              transform: rotate(30deg);
            }

            .dot {
              position: absolute;
              top: -2.5px;
              left: 100%;
              width: 6.9px;
              height: 6.9px;
              border-radius: 6.9px;
              background: #fff;

              &.inner {
                top: 98%;
                left: -2.5px;
              }
            }
          }

          p {
            margin: 6px 0 0 0;
            width: calc(66% - 21px);
            text-align: left;
            padding-left: 30%;
            @include s-breakpoints;
            font-family: "Poppins";
          }
        }
      }
    }
    &.fullservice {
      padding: 69px 0 369px 0;
      &.desktop {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      &.mobile {
        @media screen and (min-width: 768px) {
          display: none;
        }
        padding-top: 69px;

        h2 {
          margin-bottom: 42px;
        }
      }

      .slideshow {
        display: flex;
        width: 100%;
        height: 169px;
        justify-content: center;
        gap: 16px;
        .slide-control {
          padding-top: 169px;
          cursor: pointer;
          &:active {
            animation-name: clickFlash;
            animation-duration: 0.6s;
          }
          &.disabled {
            color: #0d3c51;
          }
        }
        .content {
          display: flex;
          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
          width: calc(100% - 120px);
          justify-content: center;
          .text {
            position: relative;
            width: calc(50% - 121px);
            @media screen and (max-width: 768px) {
              width: 100%;
            }
            display: flex;
            flex-direction: column;
            text-align: left;
            .slide {
              position: absolute;
              top: 0;
              left: 0;
              padding: 70px;
              @media screen and (max-width: 768px) {
                padding: 0;
              }
              opacity: 0;
              transition: opacity 0.3s ease-in;
              &.in {
                opacity: 1;
              }
              h2 {
                color: #48f0e1;
                margin-bottom: 16px;
              }
              h3 {
                @include lg-breakpoints;
                font-family: "Encode Sans Semi Bold";
                color: #fff;
                margin-bottom: 24px;
              }
              p {
                @include s-breakpoints;
                font-family: "Poppins";
                color: #fff;
              }
            }
          }
          .graphic {
            position: relative;
            width: calc(50% - 121px);
            height: 420px;
            padding: 0 80px 0 0;
            .axes {
              position: relative;
              width: 100%;
              height: 100%;
              border-left: 2px solid #fff;
              border-bottom: 2px solid #fff;
              .arrow {
                position: absolute;
                &.x {
                  top: -15px;
                  left: -7.5px;
                }
                &.y {
                  bottom: -11px;
                  left: calc(100% - 3px);
                }
              }
            }
            .fill {
              position: absolute;
              top: 20px;
              left: 2px;
              height: 400px;
              background: linear-gradient(to right, #48f0e1 0, #0d2837 75%);
              transition: width 0.3s ease-in;
              &.serve0 {
                width: 20%;
              }
              &.serve1 {
                width: 40%;
              }
              &.serve2 {
                width: 60%;
              }
              &.serve3 {
                width: 80%;
              }
              &.serve4 {
                width: 100%;
              }

              .line {
                position: absolute;
                top: 240px;
                left: 88px;
                width: 2px;
                background: #48f0e1;
                transform: rotate(59deg);
                height: 210px;
                transition: top 0.3s ease-in, left 0.3s ease-in, height 0.3s ease-in;
                &.serve0 {
                  height: 210px;
                }
                &.serve1 {
                  top: 130px;
                  left: 148px;
                  height: 350px;
                }
                &.serve2 {
                  top: 24px;
                  left: 208px;
                  height: 490px;
                }
                &.serve3 {
                  top: -80px;
                  left: 269px;
                  height: 630px;
                }
                &.serve4 {
                  top: -188px;
                  left: 328px;
                  height: 770px;
                }

                .dot {
                  position: absolute;
                  top: 0;
                  left: -5px;
                  width: 12px;
                  height: 12px;
                  border-radius: 12px;
                  border: 2px solid #fff;
                  background: #48f0e1;
                }
              }
              .triangle {
                position: absolute;
                top: -5px;
                left: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-color: #0d2837 transparent transparent transparent;
                border-width: 400px 666px 0 0;
                pointer-events: none;
              }
            }

            @media screen and (max-width: 768px) {
              position: relative;
            }

            .img {
              position: absolute;
              top: 0;
              height: 100%;
              width: 100%;
              @media screen and (max-width: 768px) {
                transform: translateY(288px);
                width: calc(100vw - 142px);
              }
              opacity: 0;
              transition: opacity 0.3s ease-in;
              &.in {
                opacity: 1;
              }
              &.token_launch {
                background: url("./img/token_launch.jpg");
                background-size: contain;
                background-repeat: no-repeat;
              }
              &.token_sale {
                background: url("./img/token_sale.jpg");
                background-size: contain;
                background-repeat: no-repeat;
              }
              &.token_rise {
                background: url("./img/token_rise.jpg");
                background-size: contain;
                background-repeat: no-repeat;
              }
              &.token_pump {
                background: url("./img/token_pump.jpg");
                background-size: contain;
                background-repeat: no-repeat;
              }
              &.token_profit {
                background: url("./img/token_profit.jpg");
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
    }
  }
}
