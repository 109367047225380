@import "../global.scss";

.team-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .desktopOnly {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .journey {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 169px;
    width: 100%;
    background: #0d2837;
    color: #ffffff;
    align-items: center;
    h1 {
      margin-bottom: 69px;
    }
    h2 {
      width: calc(100% - 420px);
      @media screen and (max-width: 1024px) {
        width: calc(100% - 69px);
      }
      color: #ffffff;
      text-align: center;
      margin-bottom: 120px;
    }
    .carousel-control {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      .arrowLeft,
      .arrowRight {
        margin-top: 30px;
        cursor: pointer;
      }
      .carousel {
        width: calc(100% - 280px);
        overflow: hidden;
        .timelines {
          position: relative;
          display: flex;
          transition: right 0.5s;
          width: 3360px;

          &.pos0 {
            right: 0;
          }
          &.pos1 {
            right: 420px;
          }
          &.pos2 {
            right: 840px;
          }
          &.pos3 {
            right: 1260px;
          }
          &.pos4 {
            right: 1680px;
          }

          .timeline {
            height: 830px;
            padding: 0 40px;
            width: 340px;
            border-left: 1px solid #ffffff;

            h2 {
              width: auto;
              margin-bottom: 40px;
              color: #48f0e1;
              text-align: left;
            }
          }
        }
      }
    }
  }
  .whiteback {
    padding-bottom: 69px;
    h3.team {
      margin-bottom: 0;
    }
    .team-members {
      width: calc(100% - 69px);
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      gap: 30px;
      margin: 69px 0 0 34.5px;
      @media screen and (max-width: 768px) {
        gap: 0;
        margin: 42px 0 0 34.5px;
      }

      .team-member {
        width: calc(25% - 30px);
        @media screen and (max-width: 768px) {
          width: calc(100% - 30px);
        }
        height: 288px;
        margin-bottom: 69px;
        text-align: left;
        .portrait {
          position: relative;
          border: 1px solid #c1c1c1;
          border-radius: 12px;
          height: 242px;
          width: 100%;

          .hoverblurb {
            display: block;
            position: absolute;
            width: calc(100% - 13.8px);
            height: calc(100% - 13.8px);
            background: #000000;
            color: #fff;
            opacity: 0;
            border-radius: 12px;
            padding: 6.9px;
            &:hover {
              opacity: 0.5;
            }
          }

          &.anthony {
            background: url("./img/anthony.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
          }
          &.scottie {
            background: url("./img/scottie.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
          }
          &.jason {
            background: url("./img/jason.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
          }
          &.kirill {
            background: url("./img/jan.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
          }
          &.ian {
            background: url("./img/yite.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
          }
          &.will {
            background: url("./img/jason.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
          }
          &.yite {
            background: url("./img/yite.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
          }
          &.awday {
            background: url("./img/jason.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
          }
          &.justin {
            background: url("./img/jan.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
          }
        }
        h1 {
          font-size: 24px;
          margin-top: 16px;
          font-weight: 700;
          font-family: "Encode Sans Bold";
          color: #0d3c51;
        }
        h2 {
          margin: 0;
          width: 100%;
          color: #0d3c51;
        }
      }
    }
    .toggles {
      display: flex;
      gap: 38px;
      margin-bottom: 69px;
      @media screen and (max-width: 768px) {
        width: calc(100% - 42px);
        gap: 16px;
        margin-bottom: 42px;
      }
      .toggle {
        border: 2px solid #c1c1c1;
        color: #c1c1c1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 64px;
        width: 192px;
        @media screen and (max-width: 768px) {
          height: 38px;
          width: 142px;
        }
        cursor: pointer;
        span {
          @include s-breakpoints;
          font-family: "Poppins";
        }
        &.selected {
          background: #0d2837;
          color: #fff;
          cursor: default;
        }
      }
    }
    .cards {
      display: flex;
      width: calc(100% - 242px);
      margin-left: 200px;
      gap: 40px;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        width: calc(100% - 42px);
        margin-left: 0;
        gap: 69px;
      }
      justify-content: left;
      flex-wrap: wrap;

      .news {
        width: calc(33% - 90px);
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        display: flex;
        flex-direction: column;
        .card {
          width: calc(100% - 60px);
          border: 1px solid #c1c1c1;
          border-radius: 16px;
          padding: 80px 30px 30px 30px;
          &.scmp {
            background: url("./img/scmp.avif");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.yuan {
            background: url("./img/yuan.jpg");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.crew {
            background: url("./img/crew.jpg");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.selloff {
            background: url("./img/selloff.jpg");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.losing {
            background: url("./img/losing.jpg");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.binancians {
            background: url("./img/binancians.jpg");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.vinai {
            background: url("./img/vinai.png");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.chaos {
            background: url("./img/chaos.png");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.scottie {
            background: url("./img/scottie.jpeg");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.miami {
            background: url("./img/miami.jpg");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.atlantis {
            background: url("./img/atlantis.jpg");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.clement {
            background: url("./img/clement.jpg");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.david {
            background: url("./img/david.jpg");
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
          &.axion {
            background: url("./img/axion.jpg");
            height: 200px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
          }
        }
        .info {
          @include s-breakpoints;
          display: flex;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-family: "Encode Sans Semi Bold";
        }
        h3 {
          @include s-breakpoints;
          height: 70px;
          margin: 24px 0 16px 0;
          @media screen and (max-width: 768px) {
            @include m-breakpoints;
            height: 42px;
            margin: 6.9px 0;
          }

          width: 100%;
          text-align: left;
          a {
            text-decoration: none;
            color: #0d2837;
          }
        }
        h2 {
          @include s-breakpoints;
          width: 100%;
          text-align: left;
        }
      }
    }
    button {
      margin-top: 42px;
      width: 369px;
      height: 80px;
      @media screen and (max-width: 768px) {
        width: calc(100% - 69px);
        height: 42px;
      }

      border: 2px solid #078077;
      border-radius: 8px;
      background-color: #ffffff;
      color: #078077;
      font-family: "Encode Sans Bold";
      @include m-breakpoints;
      cursor: pointer;
      transition: background-color 0.3s ease-out, color 0.3s ease-out;

      &:hover {
        background-color: #078077;
        color: #fff;
      }
    }
  }
  .blueback {
    padding: 69px 0;
    .logo-parade-wrapper {
      // height: fit-content;
      .logo-parade {
        height: fit-content;
      }
    }
  }
}
