.insights-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .toggles {
    width: calc(100% - 420px);
    @media screen and (max-width: 768px) {
      width: calc(100% - 42px);
    }
    display: flex;
    height: 69px;
    justify-content: left;
    color: #c1c1c1;
    margin-top: 69px;
    border-bottom: 2px solid #c1c1c1;

    .toggle {
      display: flex;
      align-items: center;
      justify-content: center;

      &.selected {
        color: #078077;
        border-bottom: 2px solid #078077;
        transform: translateY(1.5px);
      }
    }
  }
}
