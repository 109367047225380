@import "../global.scss";

.faq-wrapper {
  .columns {
    width: 100%;
    display: flex;
    gap: 40px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 16px;
    }
    margin-top: 80px;
    .column {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      display: flex;
      flex-direction: column;
      gap: 16px;
      .question-wrapper {
        .question {
          background: #f5f5f5;
          border-radius: 16px;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 24px;

          span {
            @include s-breakpoints;
            font-family: "Poppins";
            font-weight: 500;
          }

          .plus {
            @include lg-breakpoints;
            cursor: pointer;
            color: #0d3c51;
            padding-bottom: 10px;
            width: 15px;
          }
        }
        .answer-wrapper {
          overflow: hidden;
          height: 100%;
          transition: height 0.3s; //does not work, just there for delay

          &.collapsed {
            height: 0;
          }

          .answer {
            height: fit-content;
            transition: transform 0.3s ease-in;
            padding: 24px 48px;
            &.collapsed {
              transform: translateY(-100%);
            }
          }
        }
      }
    }
  }
}
