@font-face {
  font-family: "Encode Sans Thin";
  src: url("./fonts/EncodeSans_Expanded-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Encode Sans Bold";
  src: url("./fonts/EncodeSans_Expanded-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Encode Sans Semi Bold";
  src: url("./fonts/EncodeSans_Expanded-SemiBold.ttf") format("truetype");
}

@mixin xs-breakpoints {
  font-size: 13px;
  @media only screen and (max-width: 1920px) {
    font-size: 13px;
  }
}

@mixin s-breakpoints {
  font-size: 18px;
  @media only screen and (max-width: 1920px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 1600px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 13px;
  }
}

@mixin m-breakpoints {
  font-size: 22px;
  @media only screen and (max-width: 1920px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 1600px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 15px;
  }
}

@mixin lg-breakpoints {
  font-size: 34px;
  @media only screen and (max-width: 1920px) {
    font-size: 34px;
  }
  @media only screen and (max-width: 1600px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 22px;
  }
}

@mixin x-lg-breakpoints {
  font-size: 56px;
  @media only screen and (max-width: 1920px) {
    font-size: 56px;
  }
  @media only screen and (max-width: 1600px) {
    font-size: 52px;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 46px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 42px;
  }
}

@mixin xx-lg-breakpoints {
  @media only screen and (max-width: 1920px) {
    font-size: 90px;
  }
  @media only screen and (max-width: 1600px) {
    font-size: 80px;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 72px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 55px;
  }
  @media only screen and (max-width: 420px) {
    font-size: 42px;
  }
}

h1 {
  @include xx-lg-breakpoints;
  font-weight: 100;
  margin: 0;
  font-family: "Encode Sans Thin";
}

h2 {
  @include s-breakpoints;
  font-weight: 400;
  margin: 0;
  font-family: "Poppins";
}

h3 {
  @include m-breakpoints;
  color: #c3c3c3;
  font-weight: bold;
  margin: 0 0 69px 0;
  font-family: "Encode Sans Bold";
}

.buttons {
  display: flex;
  justify-content: space-around;
  width: 420px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  margin-bottom: 169px;

  button {
    width: 169px;
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
  }

  .learn {
    background: transparent;
    border: 2px solid #707070;
    color: #707070;
  }

  .invest {
    background: #707070;
    border: 0;
    color: #ffffff;
  }
}
.logo-parade-wrapper {
  @media screen and (max-width: 768px) {
    width: 100%;
    overflow: scroll;
  }
  .logo-parade {
    width: 100%;
    background: #f5f5f5;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      width: 1500px;
    }
    gap: 42px;
    margin-bottom: 107px;
    &.noback {
      background: none;
    }

    .logo {
      width: 169px;
      height: 30px;
      &.high {
        height: 60px;
      }

      &.abacus {
        background: url("./home/img/abacus.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.allme {
        background: url("./home/img/allme.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.amo {
        background: url("./home/img/amo.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform: scale(1.5);
      }
      &.blockmason {
        background: url("./home/img/blockmason.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.carry {
        background: url("./home/img/carry.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.coinbase {
        background: url("./home/img/coinbase.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.fantom {
        background: url("./home/img/fantom.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.fuzex {
        background: url("./home/img/fuzex.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform: scale(1.5);
      }
      &.gifto {
        background: url("./home/img/gifto.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.hold {
        background: url("./home/img/hold.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.iht {
        background: url("./home/img/iht.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.link {
        background: url("./home/img/link.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.lylaw {
        background: url("./home/img/lylaw.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 42px;
      }
      &.magnachain {
        background: url("./home/img/magnachain.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 42px;
      }
      &.plair {
        background: url("./home/img/plair.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.ripple {
        background: url("./home/img/ripple.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.singularity {
        background: url("./home/img/singularity.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 42px;
      }
      &.stellar {
        background: url("./home/img/stellar.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.zeex {
        background: url("./home/img/zeex.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 42px;
      }
      &.zilliqa {
        background: url("./home/img/zilliqa.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 42px;
      }
      &.sunnysideup {
        background: url("./team/img/sunnysideup.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.sunent {
        background: url("./team/img/sunent.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.allbit {
        background-color: #fff;
        background-image: url("./team/img/allbit.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 8px;
      }
      &.bitgosu {
        background-color: #fff;
        background-image: url("./team/img/bitgosu.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 8px;
      }
      &.coinhako {
        background-color: #fff;
        background-image: url("./team/img/coinhako.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 8px;
      }
      &.coinsuper {
        background: url("./team/img/coinsuper.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.coinsnetwork {
        background: url("./team/img/coinsnetwork.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.dna {
        background: url("./team/img/dna.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.eventprotocol {
        background: url("./team/img/eventprotocol.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.fintnews {
        background: url("./team/img/fintnews.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.foundationx {
        background: url("./team/img/foundationx.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.gb {
        background: url("./team/img/gb.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.hikari {
        background: url("./team/img/hikari.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.lylaw {
        background: url("./team/img/lylaw.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.wxygroup {
        background: url("./team/img/wxygroup.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.nexo {
        background: url("./home/img/gsnexo.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.orbs {
        background: url("./home/img/orbs.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.zklend {
        background: url("./home/img/zklendgs.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.dehorizon {
        background: url("./home/img/dehorizon.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.the9 {
        background: url("./home/img/the9.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.eighthours {
        background: url("./home/img/8hours.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.treelion {
        background: url("./home/img/treelion.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.axion {
        background: url("./home/img/axion.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        height: 42px;
      }
      &.cachet {
        background: url("./home/img/cachet.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.dcentral {
        background: url("./home/img/dcentral.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        height: 42px;
      }
      &.lendingblock {
        background: url("./home/img/lendingblock.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.nexmoon {
        background: url("./home/img/nexmoon.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        height: 38px;
      }
      &.coinseer {
        background: url("./home/img/coinseer.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.pundix {
        background: url("./home/img/pundix.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &.playtable {
        background: url("./home/img/playtable.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
}

.whiteback {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  &.dark {
    background: #f5f5f5;
    @media screen and (max-width: 768px) {
      width: calc(100% - 42px);
    }
  }
  width: 100%;
  text-align: center;
  color: #707070;
  padding-top: 69px;

  h2 {
    width: calc(100% - 280px);
    @media screen and (max-width: 768px) {
      width: 75%;
    }
  }
}

.blueback {
  width: 100%;
  background: #0d2837;
  &.light {
    background: #0d3c51;
  }
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info1 {
  padding: 200px 140px;
  @media screen and (max-width: 768px) {
    padding: 142px 38px 42px 38px;
  }
  color: #0d3c51;

  h1 {
    margin-bottom: 16px;
  }

  span {
    @include m-breakpoints;
    font-family: "Poppins";
  }

  .country-list {
    margin-top: 80px;
    font-family: "Poppins";
    @include s-breakpoints;
    @media screen and (max-width: 768px) {
      margin-top: 42px;
    }
  }
}

.info2 {
  padding: 160px 140px;
  @media screen and (max-width: 768px) {
    padding: 169px 42px 69px 42px;
  }
  color: #0d3c51;

  h3 {
    margin-bottom: 42px;
  }

  h1 {
    margin: 80px 0;
    @media screen and (max-width: 768px) {
      margin: 0 0 42px 0;
    }
  }

  h2 {
    &.big {
      @include lg-breakpoints;
      margin-bottom: 20px;
    }
  }

  p {
    @include s-breakpoints;
    font-family: "Poppins";
    margin-bottom: 20px;
  }
}

.stats-wrapper {
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .stats {
    display: flex;
    width: calc(100% - 420px);
    gap: 48px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      width: calc(100% - 42px);
      height: fit-content;
      padding: 69px 0;
      gap: 24px;
    }

    &.fullwidth {
      width: 100%;
      @media screen and (max-width: 768px) {
        width: calc(100% - 69px);
      }
    }
    &.tightgap {
      gap: 24px;
    }

    left: 10%;
    height: 242px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #c1c1c1;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;

    .stat {
      width: 25%;
      gap: 24px;
      @media screen and (max-width: 768px) {
        width: 100%;
        gap: 4.2px;
      }
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        @include m-breakpoints;
        margin: 0;
        font-family: "Poppins";
        font-weight: 500;
        color: #0d3c51;
        height: 45px;
        @media screen and (max-width: 768px) {
          height: 15px;
          &.addedbreak {
            height: 30px;
          }
        }
      }
      h1 {
        @include lg-breakpoints;
        margin-top: 0;
        font-family: "Encode Sans Semi Bold";
        color: #078077;
        @media screen and (max-width: 768px) {
          @include x-lg-breakpoints;
        }
      }
      &.smallp {
        p {
          @include s-breakpoints;
        }
      }
    }

    .divider {
      width: 0;
      height: 80%;
      border-right: 1px solid #e5e5e5;
      @media screen and (max-width: 768px) {
        width: 80%;
        background: #e5e5e5;
        border: 0;
        height: 1px;
      }
    }
  }
}

.tag-wrapper {
  width: 100%;
  background: #ffffff;
  h3 {
    color: #0d3c51;
    margin: 120px 0;
    @media screen and (max-width: 768px) {
      @include lg-breakpoints;
      padding: 0 42px;
    }
  }
}
