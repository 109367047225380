@import "../global.scss";

.investment-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #707070;
  text-align: center;
  background: #f5f5f5;

  .blueback {
    height: calc(100vh - 100px);
    padding: 142px 0 69px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      font-family: "Encode Sans Semi Bold";
      color: #ffffff;
      opacity: 0.5;
    }
    h2 {
      margin: 16px 0 69px 0;
      color: #ffffff;
      font-weight: 500;
      @media screen and (max-width: 768px) {
        width: calc(100% - 42px);
        @include m-breakpoints;
      }
    }
    .mobileOnly {
      display: none;
      @media screen and (max-width: 768px) {
        display: inline;
      }
    }
    .desktopOnly {
      display: inline;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .blurbs {
    display: flex;
    background: #f5f5f5;
    padding: 0 140px;
    text-align: left;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 0;
    }

    .blurb {
      height: 388px;
      position: relative;
      width: 50%;
      padding: 69px 0 0 0;

      &.left {
        padding-right: 95px;
        border-right: 0.5px solid #c1c1c1;
        @media screen and (max-width: 768px) {
          width: calc(100% - 42px);
          padding: 69px 21px;
          border-bottom: 1px solid #c1c1c1;
        }
      }
      &.right {
        padding-left: 95px;
        @media screen and (max-width: 768px) {
          width: calc(100% - 42px);
          padding: 69px 21px 42px 21px;
        }
        border-left: 0.5px solid #c1c1c1;
      }
      h3 {
        @include lg-breakpoints;
        margin: 0;
        @media screen and (max-width: 768px) {
          font-size: 36px;
          margin: 0 0 21px 0;
        }
        color: #0d3c51;
        height: 100px;
      }
      span {
        @include s-breakpoints;
        @media screen and (max-width: 768px) {
          @include m-breakpoints;
        }
        color: #0d3c51;
        font-weight: 500;
        font-family: "Poppins";
      }
      .read-more {
        position: absolute;
        bottom: 124px;
        @media screen and (max-width: 768px) {
          bottom: 69px;
        }
        font-weight: 500;
        text-decoration: underline;

        a {
          color: #078077;
          @include m-breakpoints;
        }
      }
    }
  }

  .investments-inner-wrapper {
    width: calc(100% - 280px);
    padding: 160px 140px;
    @media screen and (max-width: 768px) {
      width: calc(100% - 42px);
      padding: 42px 21px 69px 21px;
    }
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      @include lg-breakpoints;
      font-family: "Encode Sans Bold";
      color: #0d3c51;
      margin-bottom: 16px;
    }
    h2 {
      color: #c3c3c3;
    }
    span {
      color: #0d3c51;
      font-weight: 500;
      br {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    .investment-cards {
      width: calc(100% + 142px);
      display: flex;
      margin-top: 69px;
      @media screen and (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        margin-top: 42px;
      }
      flex-wrap: wrap;
      background: #ffffff;
      gap: 30px;

      .investment-card {
        display: flex;
        flex-direction: column;
        gap: 25px;
        width: calc(33% - 105px);
        padding: 42px;
        @media screen and (max-width: 768px) {
          width: calc(100% - 42px);
          padding: 21px;
        }
        background: #ffffff;
        border: 1px solid #888888;
        border-radius: 12px;
        text-align: left;
        h2,
        h3 {
          margin: 0;
        }
        h3 {
          color: #0d3c51;
          font-family: "Encode Sans Semi Bold";
          font-weight: 100;
          @include lg-breakpoints;
        }
        p {
          color: #0d3c51;
          font-family: "Poppins";
          @include s-breakpoints;
        }
      }
    }
    .stats-wrapper {
      h3 {
        margin-top: 120px;
        @media screen and (max-width: 768px) {
          margin: 69px 0 42px 0;
        }
      }
    }
    button {
      width: 369px;
      margin-top: 124px;
      @media screen and (max-width: 768px) {
        margin-top: 69px;
        width: 80%;
      }
      height: 80px;
      border: 2px solid #078077;
      border-radius: 8px;
      background: #ffffff;
      color: #078077;
      font-size: 18px;

      font-family: "Encode Sans Semi Bold";
      cursor: pointer;
      transition: background-color 0.3s ease-out, color 0.3s ease-out;

      &:hover {
        background-color: #078077;
        color: #fff;
      }
    }
  }
}
